import { first, Hash, Nullable } from "@jamesgmarks/utilities";
import { BaseSubscriptions } from "@llws/typeorm-entities";
import { Autocomplete, TextField, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { getMany } from "../../redux/features/dynamic/actions";
import { useAppSelector } from "../../redux/hooks";
import { IBaseSubscriptions } from "@llws/lift-entity-interfaces";
import { INestedWhereCommand, IWhereCommand } from "@llws/dynamic-router-utils";

const toOptionString = (bs: IBaseSubscriptions) => (
  `${bs.partner?.name ?? 'MISSING PARTNER'} - `
  + `${(bs.service?.invoiceItem ? bs.service?.invoiceItem : bs.service?.name) ?? 'MISSING SERVICE'} - `
  + `${bs.invoiceDescription} | ID: ${bs.id}`
).trim();

interface IBaseSubscriptionDropdownProps {
  label?: string,
  allowFreeform?: boolean,
  baseSubscriptionId: Nullable<number>,
  onBaseSubscriptionChanged: (baseSubscription: Nullable<IBaseSubscriptions>) => void,
  onBaseSubscriptionFreeForm?: (freeFormName: Nullable<string>) => void,
  queryFilters?: (IWhereCommand | INestedWhereCommand)[],
  preSelectFirstOption?: boolean,
}

export const BaseSubscriptionDropdown: React.FC<IBaseSubscriptionDropdownProps> = (
  {
    label = 'Base Subscription',
    allowFreeform = false,
    baseSubscriptionId,
    onBaseSubscriptionChanged,
    onBaseSubscriptionFreeForm,
    queryFilters = [],
    preSelectFirstOption = false,
  },
) => {
  const baseSubscriptions = useAppSelector((state) => state.dynamic.data.base_subscriptions?.list);

  const options = (
    (baseSubscriptions ?? [])
      .slice()
      .sort((a, b) => toOptionString(a).toLowerCase() < toOptionString(b).toLowerCase() ? -1 : 1)
  );

  useEffect(() => {
    getMany(
      'base_subscriptions',
      {
        relations: [{ path: 'service', alias: 's' }, { path: 'partner', alias: 'p' }],
        where: [
          ...(queryFilters.length > 0 ? queryFilters : []),
        ],
        limit: 9999,
      },
    );
  }, [queryFilters]);

  const [query, setQuery] = useState<Nullable<string>>(null);
  const [selectedBaseSubscription, setSelectedBaseSubscription] = useState<Nullable<IBaseSubscriptions>>(null);

  useEffect(() => {
    if (!baseSubscriptions || !baseSubscriptionId) {
      if (preSelectFirstOption && (baseSubscriptions ?? []).length > 0) {
        const firstBaseSubscription = first(baseSubscriptions!)!;
        setSelectedBaseSubscription(firstBaseSubscription);
        onBaseSubscriptionChanged(firstBaseSubscription);
        return;
      }

      setSelectedBaseSubscription(null);
      return;
    }
    const search = baseSubscriptions.find(bs => bs.id === baseSubscriptionId);
    if (search) {
      setSelectedBaseSubscription(search);
    }
  }, [baseSubscriptions, baseSubscriptionId, preSelectFirstOption, onBaseSubscriptionChanged]);

  const handleInputChange = (subSearch: Nullable<string>) => {
    setQuery(subSearch);

    if (allowFreeform) {
      onBaseSubscriptionFreeForm!(subSearch);
    };
  };

  const handleBaseSubSelected = useCallback(
    (baseSubscription: Nullable<IBaseSubscriptions>) => {
      setSelectedBaseSubscription(baseSubscription);

      onBaseSubscriptionChanged(baseSubscription);

      allowFreeform && onBaseSubscriptionFreeForm!(baseSubscription ? toOptionString(baseSubscription) : '');
    }, [allowFreeform, onBaseSubscriptionChanged, onBaseSubscriptionFreeForm],
  );

  return (
    <Autocomplete
      id="base-subscription-selector"
      autoComplete
      autoHighlight={!allowFreeform}
      clearOnBlur={!allowFreeform}
      clearOnEscape
      freeSolo={allowFreeform}
      fullWidth
      getOptionLabel={
        (option: unknown) => {
          if (typeof option === 'string') {
            return option;
          } else if (option && (option as Hash).inputValue) {
            // Create a new value from the user input
            return (option as Hash).inputValue;
          } else {
            // TODO: Use typeguard when available in library
            const bs = option as IBaseSubscriptions;
            return toOptionString(bs);
          }
        }
      }
      inputValue={query ?? ''}
      onChange={
        (_e, newValue: unknown, _reason) => {
          if (typeof newValue === 'string') {
            handleInputChange(newValue);
          } else if (newValue && (newValue as Hash).inputValue) {
            // Create a new value from the user input
            handleInputChange((newValue as Hash).inputValue);
          } else if ((newValue as BaseSubscriptions)?.created) { // TODO: Use typeguard when available in library
            handleBaseSubSelected(newValue as IBaseSubscriptions);
          } else {
            handleBaseSubSelected(null);
          }
        }
      }
      onInputChange={
        (_e, value: string, _reason) => {
          handleInputChange(value);
        }
      }
      options={options}
      renderInput={
        (params) => (
          <TextField
            {...params}
            label={label}
            sx={{ backgroundColor: '#fff' }}
            variant="outlined"
          />
        )
      }
      renderOption={
        (props, option: unknown) => {
          // TODO: Use typeguard when available in library
          const o = option as IBaseSubscriptions;

          return (
            <li {...props}>
              <Typography>
                <span style={{ color: 'dodgerblue', fontWeight: 550 }}>
                  {toOptionString(o)}
                </span>
              </Typography>
            </li>
          );
        }
      }

      size="small"
      value={selectedBaseSubscription}
    />
  );
};
