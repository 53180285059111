import { Accordion, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledAccordion = styled(Accordion)(
  ({ hasErrors = false }: { hasErrors?: boolean; }) => ({
    ...(hasErrors && {
      border: "2px solid red",
    }),
  }),
);

export const StyledTextField = styled(TextField)(
  ({ hasErrors = false }: { hasErrors?: boolean; }) => ({
    ...(hasErrors && {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "red",
        },
      },
    }),
  }),
);
