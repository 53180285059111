import { Hash } from "@jamesgmarks/utilities";

interface IJsonRendererProps {
  obj: Hash;
  backgroundColor?: string;
  keyReplacements?: Record<string, string>;
  dollarValueKeys?: string[];
  customStyles?: Record<string, React.CSSProperties>;
}

const formatKey = (key: string, keyReplacements?: Record<string, string>) => {
  if (keyReplacements && keyReplacements[key]) {
    return keyReplacements[key];
  }
  return key.replace(/([A-Z])/g, " $1").replace(/^./, (str) => str.toUpperCase());
};

const formatValue = (key: string, value: unknown, dollarValueKeys?: string[]): React.ReactNode => {
  if (dollarValueKeys?.includes(key) && typeof value === "number") {
    return <span style={{ color: "green" }}>${value.toFixed(2)}</span>;
  }
  return `${value}`;
};

const renderJson = (
  data: unknown,
  depth: number = 0,
  keyReplacements?: Record<string, string>,
  dollarValueKeys?: string[],
  customStyles?: Record<string, React.CSSProperties>,
): React.ReactNode => {
  if (typeof data === "object" && data !== null) {
    if (Array.isArray(data)) {
      return (
        <ul style={{ paddingLeft: `${depth * 20}px`, margin: 0, marginTop: "5px" }}>
          {data.map((item, index) => (
            <li
              key={index}
              style={{
                listStyleType: "none",
                marginBottom: "10px", // Vertical separation for array items
              }}
            >
              {renderJson(item, depth + 1, keyReplacements, dollarValueKeys, customStyles)}
            </li>
          ))}
        </ul>
      );
    } else {
      const entries = Object.entries(data);
      const longestKeyLength = Math.max(...entries.map(([key]) => formatKey(key, keyReplacements).length));
      const keyColumnWidth = `${longestKeyLength + 2}ch`;

      return (
        <div style={{ paddingLeft: `${depth * 20}px` }}>
          {entries.map(([key, value]) => (
            <div
              key={key}
              style={{
                display: "flex",
                ...(customStyles?.[key] ?? {}), // Apply custom styles for this key, if specified
              }}
            >
              <div
                style={{
                  minWidth: keyColumnWidth, // Align keys
                  fontWeight: "bold",
                }}
              >
                {formatKey(key, keyReplacements)}:
              </div>
              <div>
                {typeof value === "object"
                  ? renderJson(value, depth + 1, keyReplacements, dollarValueKeys, customStyles)
                  : formatValue(key, value, dollarValueKeys)}
              </div>
            </div>
          ))}
        </div>
      );
    }
  }

  // Primitive values
  return <span>{String(data)}</span>;
};

export const JsonRenderer: React.FC<IJsonRendererProps> = ({
  obj,
  backgroundColor,
  keyReplacements,
  dollarValueKeys,
  customStyles,
}) => (
  <div
    style={{
      background: backgroundColor ?? '#fff',
      borderRadius: '15px',
      color: 'black',
      display: 'block',
      padding: '10px 20px',
      textAlign: 'left',
      whiteSpace: 'pre-wrap',
    }}
  >
    {renderJson(obj, 0, keyReplacements, dollarValueKeys, customStyles)}
  </div>
);
