import { Link, Route, Switch } from "react-router-dom";
import { FeesManager } from "./FeesManager";
import { SubscriptionEditor } from "./Editor";
import { SubscriptionList } from "./SubscriptionList";
import { TieredUnitCountEditor } from "./tiered-unit-count/TieredUnitCountEditor";
import { CreateTieredUnitCountSubscriptionLink } from "./tiered-unit-count/CreateTieredUnitCountSubscriptionLink";

export const SubscriptionManagement = () => {
  return (
    <>
      <Switch>
        <Route path="/subscriptions/fees"><FeesManager /></Route>
        <Route path="/subscriptions/list"><SubscriptionList /></Route>
        <Route
          path={[
            "/subscriptions/tiered-unit-count/:subscriptionId",
            "/subscriptions/tiered-unit-count",
          ]}
          component={TieredUnitCountEditor}
        />
        <Route path="/subscriptions/:subscriptionId"><SubscriptionEditor /></Route>
        <Route path="/subscriptions">
          <header className="Section-header">Subscription Management</header>
          <ul>
            <li><Link to="/subscriptions/fees">Fees</Link></li>
            <li><Link to="/subscriptions/list">Subscription List</Link></li>
            <br />
            <li><CreateTieredUnitCountSubscriptionLink /></li>
          </ul>
        </Route>
      </Switch >
    </>
  );
};
