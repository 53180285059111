import { Box, Button, Grid, Paper, TextField } from "@mui/material";
import { StyledTextField } from "./styledComponents";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  createOneAndReturnId, deleteOne, getMany, getOne, updateOne,
} from "src/redux/features/dynamic/actions";
import { Equal, TWhereCondition } from "@llws/dynamic-router-utils";
import { useAppSelector } from "src/redux/hooks";
import { ClientDropdown } from "src/components/parts/ClientDropdown";
import { BaseSubscriptionDropdown } from "src/components/parts/BaseSubscriptionDropdown";
import { HHDatePicker } from "src/components/parts/HHDatePicker";
import { dateFromYmd, makeYmd } from "src/app-utils";
import {
  first, floatVal, hasDiff, intVal, keys, mapAsync, Nullable,
} from "@jamesgmarks/utilities";
import { IBaseSubscriptions, TJsonNote } from "@llws/lift-entity-interfaces";
import {
  isITieredUnitCountConfiguration,
  ITieredUnitCountConfiguration,
  BILLING_FREQUENCY_MONTHLY,
  BILLING_TYPE_TIERED_UNIT_COUNT,
} from "@llws/hydra-shared";
import { NotesEditor } from "../EditorComponents/NotesEditor/NotesEditor";
import { CommissionEditor, ICommissionSchemeData } from "../EditorComponents/CommissionEditor/CommissionEditor";
import { showMessage } from "src/redux/features/messaging/actions";
import { TieredUnitCountConfigurationAccordion } from "./TieredUnitCountConfigurationAccordion";
import "./tieredUnitCountEditor.css";
import { BuildingsEditor } from "../EditorComponents/BuildingsEditor";
import { CreateTieredUnitCountSubscriptionLink } from "./CreateTieredUnitCountSubscriptionLink";

export interface ITieredUnitCountSubscriptionData {
  baseSubscriptionId: Nullable<number>;
  clientId: Nullable<number>;
  clientFreshbooksId: Nullable<number>;
  startDate: Nullable<string>;
  expiryDate: Nullable<string>;
  rateExpiryDate: Nullable<string>;
  minimumPropertiesForRate: Nullable<number>;
  discountRate: number;
  quoteId: string;
  revenueTrackingCode: string;
  invoiceDescription: string;
  notes: Nullable<TJsonNote[]>;
  baseSubscriptionConfiguration: Nullable<ITieredUnitCountConfiguration>;
  subscriptionConfiguration: Nullable<ITieredUnitCountConfiguration>;
  buildingsData: { id: number; name: string; }[];
}

export const TieredUnitCountEditor: React.FC = () => {
  const [subscriptionLastSaved, setSubscriptionLastSaved] = useState(new Date());

  const history = useHistory();

  const { subscriptionId: _subscriptionId } = useParams<{ subscriptionId?: string; }>();
  const subscriptionId = /^[0-9]+$/.test(_subscriptionId ?? '') ? intVal(_subscriptionId) : null;

  const fetchCommissionSchemes = useCallback(
    async (subscriptionId: number) => {
      getMany(
        'commission_schemes',
        { where: [Equal('subscriptionId', subscriptionId)] },
      );
    }, [],
  );

  const fetchSubscription = (
    async (subscriptionId: number) => {
      getOne('subscriptions', subscriptionId, [
        { path: 'client', alias: 'c' },
        { path: 'variableBasePriceOverrides', alias: 'vbpo' },
        { path: 'billingZoneCityOverrides', alias: 'bzco' },
        { path: 'bzco.ownershipGroup', alias: 'bzcoog' },
        { path: 'bzco.city', alias: 'bzcogc' },
        { path: 'bzcogc.province', alias: 'bzcogp' },
        { path: 'baseSubscription', alias: 'bs' },
        { path: 'bs.variableBasePrices', alias: 'vbp' },
        { path: 'bs.billingZones', alias: 'bz' },
        { path: 'bz.billingZoneCities', alias: 'bzc' },
        { path: 'bzc.city', alias: 'gc' },
        { path: 'gc.province', alias: 'gp' },
        { path: 'bs.service', alias: 'svc' },
        { path: 'bs.partner', alias: 'p' },
        { path: 'subscriptionsHasBuildings', alias: 'shb' },
        { path: 'shb.building', alias: 'shbb' },
        { path: 'parentSubscription', alias: 'ps' },
        { path: 'workInProgressQueues', alias: 'wipqs' },
        { path: 'wipqs.workInProgress', alias: 'wip' },
        { path: 'salesRep', alias: 'sr' },
        { path: 'parentSalesRep', alias: 'psr' },
        { path: 'sr.user', alias: 'u' },
        { path: 'psr.user', alias: 'pu' },
        { path: 'commissionSchemes', alias: 'cs' },
      ]);
    }
  );

  useEffect(() => {
    if (subscriptionId) {
      fetchSubscription(subscriptionId);
    }
  }, [subscriptionId]);

  const storeSubscription = (
    useAppSelector((store) => store.dynamic.data.subscriptions?.single));

  useEffect(() => {
    if (storeSubscription) {
      history.push(`/subscriptions/tiered-unit-count/${storeSubscription.id}`);
    }
  }, [history, storeSubscription]);

  const getEmptySubscriptionData = (): ITieredUnitCountSubscriptionData => ({
    baseSubscriptionId: null,
    clientId: null,
    clientFreshbooksId: null,
    startDate: null,
    expiryDate: null,
    rateExpiryDate: null,
    minimumPropertiesForRate: null,
    discountRate: 0,
    quoteId: '',
    revenueTrackingCode: '',
    invoiceDescription: '',
    notes: null,
    baseSubscriptionConfiguration: null,
    subscriptionConfiguration: null,
    buildingsData: [],
  });

  const [discountRateFormValue, setDiscountRateFormValue] = useState('');
  const [minimumPropertiesForRateFormValue, setMinimumPropertiesForRateFormValue] = useState('');

  const subscriptionDataFromStoreSubscription = useMemo(
    (): ITieredUnitCountSubscriptionData => {
      const baseSubscriptionConfiguration = (
        storeSubscription?.baseSubscription?.customConfiguration?.tieredUnitCountConfig ?? null);

      const subscriptionConfiguration = (
        storeSubscription?.customConfiguration?.tieredUnitCountConfig ?? null);

      if (storeSubscription) {
        // Comes back as string...
        const discountRate = floatVal(storeSubscription.discountRate);

        setDiscountRateFormValue(`${discountRate}`);
        setMinimumPropertiesForRateFormValue(`${storeSubscription.minPropertiesForRate ?? ''}`);

        return (
          {
            baseSubscriptionId: storeSubscription.baseSubscriptionId,
            clientId: storeSubscription.clientId,
            clientFreshbooksId: storeSubscription.client?.freshbooksClientId ?? null,
            startDate: storeSubscription.startDate ?? null,
            expiryDate: storeSubscription.expiryDate ?? null,
            rateExpiryDate: storeSubscription.rateExpiryDate ?? null,
            minimumPropertiesForRate: storeSubscription.minPropertiesForRate ?? null,
            discountRate,
            quoteId: storeSubscription.quoteId ?? '',
            revenueTrackingCode: storeSubscription.revenueTrackingCode ?? '',
            invoiceDescription: storeSubscription.invoiceDescription,
            notes: storeSubscription.notes ?? null,
            baseSubscriptionConfiguration: (
              isITieredUnitCountConfiguration(baseSubscriptionConfiguration)
                ? baseSubscriptionConfiguration
                : null
            ),
            subscriptionConfiguration: (
              isITieredUnitCountConfiguration(subscriptionConfiguration)
                ? subscriptionConfiguration
                : null
            ),
            buildingsData: (
              storeSubscription.subscriptionsHasBuildings?.map((shb) => ({
                id: shb.buildingId,
                name: shb.building?.buildingName ?? '',
              })) ?? []
            ),
          }
        );
      }

      return getEmptySubscriptionData();
    }, [storeSubscription],
  );

  const commissionSchemeDataFromStoreSubscription = useMemo(
    (): ICommissionSchemeData => {
      const salesRepId = (
        storeSubscription?.salesRepId
        ?? storeSubscription?.client?.salesRepId
        ?? null
      );

      const commissionScheme = first(storeSubscription?.commissionSchemes ?? []) ?? null;

      // `id`, `scheme`, and `label` will be later set by `CommissionEditor`
      return {
        id: commissionScheme?.id ?? null,
        subscriptionId,
        salesRepId,
        scheme: commissionScheme?.scheme ?? null,
        label: commissionScheme?.label ?? '',
      };
    },
    [storeSubscription, subscriptionId],
  );

  const [subscriptionData, setSubscriptionData] = (
    useState<ITieredUnitCountSubscriptionData>(subscriptionDataFromStoreSubscription));

  const [commissionSchemeData, setCommissionSchemeData] = (
    useState<ICommissionSchemeData>(commissionSchemeDataFromStoreSubscription));

  const [selectedBaseSubscription, setSelectedBaseSubscription] = (
    useState<Nullable<IBaseSubscriptions>>(storeSubscription?.baseSubscription ?? null));

  const [selectedClientSalesRepId, setSelectedClientSalesRepId] = (
    useState<Nullable<number>>(storeSubscription?.client?.salesRepId ?? null));

  useEffect(() => {
    setSubscriptionData(subscriptionDataFromStoreSubscription);
    setSelectedBaseSubscription(storeSubscription?.baseSubscription ?? null);
    setSelectedClientSalesRepId(storeSubscription?.client?.salesRepId ?? null);
  }, [storeSubscription, subscriptionDataFromStoreSubscription]);

  useEffect(() => {
    setCommissionSchemeData(commissionSchemeDataFromStoreSubscription);
  }, [commissionSchemeDataFromStoreSubscription]);

  const isFormValid = useMemo(() => {
    const {
      baseSubscriptionId, clientId, startDate, expiryDate, invoiceDescription,
      subscriptionConfiguration, baseSubscriptionConfiguration, notes,
    } = subscriptionData;

    const { salesRepId, scheme, label } = commissionSchemeData;

    return Boolean(
      // Subscription
      clientId
      && baseSubscriptionId
      && startDate
      && invoiceDescription.trim().length > 0
      && subscriptionConfiguration
      && baseSubscriptionConfiguration
      && (expiryDate === null || expiryDate >= startDate)
      && (notes ?? []).length > 0
      // Commission scheme for subscription/sales rep
      && salesRepId
      && scheme
      && label.trim().length > 0,
    );
  }, [commissionSchemeData, subscriptionData]);

  const [
    isEditingSubscriptionCommission,
    setIsEditingSubscriptionCommission,
  ] = useState(false);

  const [
    isEditingSubscriptionConfiguration,
    setIsEditingSubscriptionConfiguration,
  ] = useState(false);

  const onSaveSubscription = async () => {
    if (!commissionSchemeData.salesRepId) {
      const message = 'Could not find sales rep for commissions';
      showMessage({ message, severity: 'error' });
      return;
    }

    if (!commissionSchemeData.scheme) {
      const message = 'Could not find scheme for commissions';
      showMessage({ message, severity: 'error' });
      return;
    }

    const subscriptionBodyData = {
      clientId: subscriptionData.clientId,
      baseSubscriptionId: subscriptionData.baseSubscriptionId,
      startDate: subscriptionData.startDate,
      expiryDate: subscriptionData.expiryDate,
      rateExpiryDate: subscriptionData.rateExpiryDate,
      minPropertiesForRate: subscriptionData.minimumPropertiesForRate,
      discountRate: subscriptionData.discountRate,
      quoteId: subscriptionData.quoteId.trim() || null,
      revenueTrackingCode: subscriptionData.revenueTrackingCode.trim() || null,
      invoiceDescription: subscriptionData.invoiceDescription.trim(),
      salesRepId: commissionSchemeData.salesRepId,
      notes: subscriptionData.notes,
      customConfiguration: {
        ...(storeSubscription?.customConfiguration ?? {}),
        tieredUnitCountConfig: subscriptionData.subscriptionConfiguration,
      },
    };

    const newBuildingIdsForSubscription = subscriptionData.buildingsData.map(({ id }) => id);

    const { id: commissionSchemeId, salesRepId, label, scheme } = commissionSchemeData;

    if (subscriptionId && storeSubscription) {
      await updateOne(
        'subscriptions',
        subscriptionBodyData,
        subscriptionId,
      );

      if (commissionSchemeId) {
        await updateOne(
          'commission_schemes',
          {
            salesRepId,
            label: label.trim(),
            scheme: {
              ...scheme,
              version: (
                `${intVal(scheme.version) + (
                  hasDiff(
                    first(storeSubscription.commissionSchemes ?? [])?.scheme ?? {},
                    scheme,
                  ) ? 1 : 0
                )}`
              ),
            },
          },
          commissionSchemeId,
          'lift',
          false,
        );
      } else {
        await createOneAndReturnId(
          'commission_schemes',
          {
            subscriptionId,
            salesRepId,
            label: label.trim(),
            scheme,
          },
          'lift',
          false,
        );
      }

      const currentSubscriptionsHasBuildings = storeSubscription.subscriptionsHasBuildings ?? [];

      const subscriptionsHasBuildingsIdsToDelete = (
        currentSubscriptionsHasBuildings
          .filter(({ buildingId }) => !newBuildingIdsForSubscription.includes(buildingId))
          .map(({ id }) => id)
      );

      const currentSubscriptionsHasBuildingsBuildingIds = (
        currentSubscriptionsHasBuildings.map((shb) => shb.buildingId));

      const buildingIdsToCreateSubscriptionsHasBuildingsRecordsFor = (
        newBuildingIdsForSubscription
          .filter((buildingId) => !currentSubscriptionsHasBuildingsBuildingIds.includes(buildingId))
      );

      await mapAsync(
        subscriptionsHasBuildingsIdsToDelete,
        async (shbId) => {
          if (shbId) {
            deleteOne('subscriptions_has_buildings', shbId, 'lift', false);
          }
        },
      );

      await mapAsync(
        buildingIdsToCreateSubscriptionsHasBuildingsRecordsFor,
        async (buildingId) => {
          createOneAndReturnId(
            'subscriptions_has_buildings',
            {
              subscriptionId,
              buildingId,
            },
            'lift',
            false,
          );
        },
      );

      setSubscriptionLastSaved(new Date());
      fetchCommissionSchemes(subscriptionId);
      fetchSubscription(subscriptionId);
      return;
    }

    const createdSubscriptionId: Nullable<number> = (
      await createOneAndReturnId(
        'subscriptions',
        {
          ...subscriptionBodyData,
          billingFrequencyId: BILLING_FREQUENCY_MONTHLY,
        },
      )
    );

    if (!createdSubscriptionId) {
      const message = 'Subscription could not be created';
      showMessage({ message, severity: 'error' });
      return;
    }

    await mapAsync(
      newBuildingIdsForSubscription,
      async (buildingId) => {
        createOneAndReturnId(
          'subscriptions_has_buildings',
          {
            subscriptionId: createdSubscriptionId,
            buildingId,
          },
          'lift',
          false,
        );
      },
    );

    await createOneAndReturnId(
      'commission_schemes',
      {
        subscriptionId: createdSubscriptionId,
        salesRepId,
        label: label.trim(),
        scheme,
      },
      'lift',
      false,
    );

    setSubscriptionLastSaved(new Date());
    fetchCommissionSchemes(createdSubscriptionId);
    fetchSubscription(createdSubscriptionId);
  };

  useEffect(() => {
    setDiscountRateFormValue(`${subscriptionData.discountRate}`);
  }, [subscriptionData.discountRate]);

  const onUpdateCommissionScheme = useCallback(
    (updatedCommissionSchemeData: ICommissionSchemeData) => {
      setCommissionSchemeData(updatedCommissionSchemeData);
    }, [],
  );

  const baseSubscriptionQueryFilters = useMemo(
    () => [
      {
        field: 's.billingTypeId',
        value: `${BILLING_TYPE_TIERED_UNIT_COUNT}`,
        condition: 'or' as TWhereCondition,
      },
      {
        field: 'id',
        value: `${subscriptionData.baseSubscriptionId}`,
        condition: 'or' as TWhereCondition,
      },
    ], [subscriptionData.baseSubscriptionId],
  );

  const subscriptionNotes = useMemo(
    () => subscriptionData.notes ?? [],
    [subscriptionData.notes],
  );

  const onDeleteNote = useCallback(
    (id: string) => {
      setSubscriptionData((old) => ({
        ...old,
        notes: (old.notes ?? [])
          .filter((note) => `${note.note}${note.timestamp}` !== id),
      }));
    }, [],
  );

  const onAddNote = useCallback(
    (newNote: TJsonNote) => {
      setSubscriptionData((old) => ({
        ...old,
        notes: [newNote, ...(old.notes ?? [])],
      }));

      setIsEditingSubscriptionConfiguration(false);
    }, [],
  );

  const onBuildingChanged = useCallback(
    (building) => {
      setSubscriptionData((old) => ({
        ...old,
        buildingsData: [
          ...old.buildingsData.filter(({ id }) => id !== building.id),
          {
            id: building.id,
            name: building.buildingName,
          },
        ],
      }));
    }, [],
  );

  const onBuildingRemoved = useCallback(
    (id) => {
      setSubscriptionData((old) => ({
        ...old,
        buildingsData: old.buildingsData.filter((b) => b.id !== id),
      }));
    }, [],
  );

  const getDefaultRateExpiryDateFromSubscriptionStartDate = (d: Nullable<string>): Nullable<Date> => {
    if (d === null) {
      return null;
    }

    const originalDate = dateFromYmd(d);
    const isFirstOfMonth = originalDate.getDate() === 1;

    const oneYearLater = new Date(originalDate);
    oneYearLater.setFullYear(oneYearLater.getFullYear() + 1);

    if (isFirstOfMonth) {
      oneYearLater.setDate(0);
    } else {
      oneYearLater.setMonth(oneYearLater.getMonth() + 1);
      oneYearLater.setDate(0);
    }

    return oneYearLater;
  };

  return (
    <>
      <h2 className="mt-1">
        {
          subscriptionId
            ? `Edit Unit-Count Based Subscription (${subscriptionId})`
            : 'Create Unit-Count Based Subscription'
        }
      </h2>

      <Box mt={2} textAlign='center'>
        <CreateTieredUnitCountSubscriptionLink
          textOverride="Create another Unit-Count Based Subscription" />
      </Box>

      <Grid container justifyContent="center" alignItems="center" mx='auto' mt={2} mb={5} maxWidth={1000}>
        <Grid item xs={12} p={2}>
          <Paper variant='outlined' className="tiered-unit-count-paper">
            <Grid item container spacing={3} xs={12}>
              <Grid item xs={12}>
                <ClientDropdown
                  clientId={subscriptionData.clientId ?? null}
                  onClientChanged={(client) => {
                    if (!client) {
                      setSubscriptionData((old) => ({
                        ...old,
                        clientId: null,
                        clientFreshbooksId: null,
                      }));

                      setSelectedClientSalesRepId(null);
                    } else if (client.id !== subscriptionData.clientId) {
                      setSubscriptionData((old) => ({
                        ...old,
                        clientId: client.id,
                        clientFreshbooksId: client.freshbooksClientId,
                      }));

                      setSelectedClientSalesRepId(client.salesRepId);

                      if (client.salesRepId) {
                        setCommissionSchemeData((old) => ({ ...old, salesRepId: client.salesRepId }));
                      }
                    }
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <BaseSubscriptionDropdown
                  label='Base subscription'
                  baseSubscriptionId={subscriptionData.baseSubscriptionId ?? null}
                  queryFilters={baseSubscriptionQueryFilters}
                  onBaseSubscriptionChanged={(baseSubscription) => {
                    const config = (
                      baseSubscription?.customConfiguration?.tieredUnitCountConfig ?? null);

                    if (!(
                      baseSubscription?.service?.billingTypeId === BILLING_TYPE_TIERED_UNIT_COUNT
                      && isITieredUnitCountConfiguration(config)
                    )) {
                      setSelectedBaseSubscription(null);
                      setSubscriptionData((old) => ({
                        ...old,
                        baseSubscriptionId: null,
                        baseSubscriptionConfiguration: null,
                        invoiceDescription: '',
                      }));

                      return;
                    }

                    setSubscriptionData((old) => ({
                      ...old,
                      baseSubscriptionId: baseSubscription.id ?? null,
                      baseSubscriptionConfiguration: config,
                      invoiceDescription: baseSubscription.invoiceDescription,
                    }));

                    setSelectedBaseSubscription(baseSubscription);
                  }}
                  preSelectFirstOption={true}
                />

                {
                  selectedBaseSubscription?.service?.billingTypeId !== BILLING_TYPE_TIERED_UNIT_COUNT
                    ? (
                      <p style={{ color: 'red', margin: '7px 0 0 8px', fontSize: '0.9rem' }}>
                        Please select a valid Unit-Count Based base subscription, above.
                      </p>
                    )
                    : null
                }
              </Grid>

              <Grid item xs={12} container spacing={1}>
                {
                  keys(getEmptySubscriptionData())
                    .filter((key) => ['startDate', 'expiryDate'].includes(key))
                    .map(
                      (key) => (
                        <Grid key={key} item xs={3}>
                          <HHDatePicker
                            label={
                              `${([first(key.split('Date'))!]
                                .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()))} date`
                            }
                            value={subscriptionData[key] ? dateFromYmd(subscriptionData[key] as string) : null}
                            onChange={(date: Nullable<Date>) => {
                              setSubscriptionData((old) => ({
                                ...old,
                                [key]: (
                                  (date === null || isNaN(date.getTime())) ? null : makeYmd(date, true)
                                ),
                              }));
                            }}
                          />
                        </Grid>
                      ),
                    )
                }

                <Grid item xs={2}>
                  <TextField
                    label="Discount"
                    type="text"
                    size="small"
                    fullWidth
                    sx={{ background: "white" }}
                    value={discountRateFormValue}
                    onChange={(e) => {
                      if (/^$|^1$|^0(\.\d{0,8})?$/.test(e.target.value)) {
                        setDiscountRateFormValue(e.target.value);
                      }
                    }}
                    onBlur={() => {
                      setSubscriptionData((old) => ({
                        ...old,
                        discountRate: (
                          discountRateFormValue === ''
                            ? 0
                            : floatVal(discountRateFormValue)
                        ),
                      }));
                    }}
                  />
                </Grid>

                <Grid item xs={2}>
                  <TextField
                    label="Quote ID"
                    type="text"
                    size="small"
                    fullWidth
                    sx={{ background: "white" }}
                    value={subscriptionData.quoteId}
                    onChange={(e) => {
                      setSubscriptionData((old) => ({
                        ...old,
                        quoteId: e.target.value,
                      }));
                    }}
                  />
                </Grid>

                <Grid item xs={2}>
                  <TextField
                    label="Revenue tracking code"
                    type="text"
                    size="small"
                    fullWidth
                    sx={{ background: "white" }}
                    value={subscriptionData.revenueTrackingCode}
                    onChange={(e) => {
                      setSubscriptionData((old) => ({
                        ...old,
                        revenueTrackingCode: e.target.value,
                      }));
                    }}
                  />
                </Grid>
              </Grid>

              <Grid item xs={6}>
                <StyledTextField
                  hasErrors={subscriptionData.invoiceDescription.trim().length === 0}
                  label="Invoice Description"
                  type="text"
                  size="small"
                  fullWidth
                  sx={{ background: "white" }}
                  value={subscriptionData.invoiceDescription}
                  onChange={(e) => {
                    setSubscriptionData((old) => ({ ...old, invoiceDescription: e.target.value }));
                  }}
                />
              </Grid>

              <Grid item xs={3}>
                <HHDatePicker
                  label="Rate expiry date"
                  value={
                    subscriptionData.rateExpiryDate
                      ? dateFromYmd(subscriptionData.rateExpiryDate)
                      : getDefaultRateExpiryDateFromSubscriptionStartDate(subscriptionData.startDate)
                  }
                  onChange={(date: Nullable<Date>) => {
                    setSubscriptionData((old) => ({
                      ...old,
                      rateExpiryDate: (
                        (date === null || isNaN(date.getTime())) ? null : makeYmd(date, true)
                      ),
                    }));
                  }}
                />
              </Grid>

              <Grid item xs={3}>
                <TextField
                  label="Minimum properties for rate"
                  type="text"
                  size="small"
                  fullWidth
                  sx={{ background: "white" }}
                  value={minimumPropertiesForRateFormValue}
                  onChange={(e) => {
                    if (/^([1-9]\d*)?$/.test(e.target.value)) {
                      setMinimumPropertiesForRateFormValue(e.target.value);
                    }
                  }}
                  onBlur={() => {
                    setSubscriptionData((old) => ({
                      ...old,
                      minimumPropertiesForRate: (
                        minimumPropertiesForRateFormValue === ''
                          ? null
                          : intVal(minimumPropertiesForRateFormValue)
                      ),
                    }));
                  }}
                />
              </Grid>

              <Grid item xs={12} mt={3}>
                <BuildingsEditor
                  buildingsData={subscriptionData.buildingsData}
                  clientAccountId={subscriptionData.clientFreshbooksId}
                  onBuildingChanged={onBuildingChanged}
                  onBuildingRemoved={onBuildingRemoved}
                />
              </Grid>

              <Grid item xs={12}>
                <TieredUnitCountConfigurationAccordion
                  isEditingSubscriptionConfiguration={isEditingSubscriptionConfiguration}
                  setIsEditingSubscriptionConfiguration={setIsEditingSubscriptionConfiguration}
                  baseSubscriptionId={subscriptionData.baseSubscriptionId}
                  baseSubscriptionConfiguration={subscriptionData.baseSubscriptionConfiguration}
                  subscriptionConfiguration={subscriptionData.subscriptionConfiguration}
                  setSubscriptionData={setSubscriptionData}
                  storeSubscription={storeSubscription}
                  hasErrors={
                    !(
                      subscriptionData.baseSubscriptionConfiguration
                      && subscriptionData.subscriptionConfiguration
                    )
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <CommissionEditor
                  disabled={
                    !selectedBaseSubscription
                    || selectedBaseSubscription.service?.billingTypeId !== BILLING_TYPE_TIERED_UNIT_COUNT
                  }
                  isEditingSubscriptionCommission={isEditingSubscriptionCommission}
                  setIsEditingSubscriptionCommission={setIsEditingSubscriptionCommission}
                  baseSubscriptionId={selectedBaseSubscription?.id ?? null}
                  subscriptionId={subscriptionId}
                  salesRepId={commissionSchemeData.salesRepId ?? selectedClientSalesRepId}
                  onUpdateScheme={onUpdateCommissionScheme}
                  hasErrors={
                    !( // Not disabled
                      !selectedBaseSubscription
                      || selectedBaseSubscription.service?.billingTypeId !== BILLING_TYPE_TIERED_UNIT_COUNT
                    )
                    && !(
                      commissionSchemeData.salesRepId
                      && commissionSchemeData.scheme
                      && commissionSchemeData.label.trim().length > 0
                    )
                  }
                  fetchCommissionSchemes={fetchCommissionSchemes}
                />
              </Grid>

              <Grid item xs={12}>
                <NotesEditor
                  hasErrors={(subscriptionData.notes ?? []).length === 0}
                  notes={subscriptionNotes}
                  onDeleteNote={onDeleteNote}
                  onAddNote={onAddNote}
                  subscriptionLastSaved={subscriptionLastSaved}
                />
              </Grid>

              <Grid item xs={12} mt={5} mb={2} textAlign='center'>
                <Button
                  color="success"
                  disabled={
                    !isFormValid
                    || isEditingSubscriptionConfiguration
                    || isEditingSubscriptionCommission
                  }
                  variant="contained"
                  onClick={onSaveSubscription}
                >
                  {`${subscriptionId ? 'Save' : 'Create'} Subscription`}
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};
