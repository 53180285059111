import { floatVal, intVal, Nullable } from "@jamesgmarks/utilities";
import {
  Grid,
  Paper,
  Table as MuiTable,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TextField,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { Equal, IQueryCommandOptions, Like } from '@llws/dynamic-router-utils';
import { IClientDropdownItem } from "../../../entity-interfaces/IClientDropdownItem";
import { getMany } from "../../../redux/features/dynamic/actions";
import { useAppSelector } from "../../../redux/hooks";
import { ClientDropdown } from "../../parts/ClientDropdown";
import { StyledTableCell, StyledTableRow } from "../../parts/mui/StyledTables";
import { Subscriptions } from "@llws/typeorm-entities";
import { Spinner } from "../../parts/Spinner";
import { Link } from "react-router-dom";
import { getDiscountRateFromSubscription } from "src/app-utils";
import { rowsPerPageList } from "../Clients/Tabs";
import { BILLING_TYPE_TIERED_UNIT_COUNT } from "@llws/hydra-shared";

const relationsQueryCommand: IQueryCommandOptions = {
  alias: 's',
  relations: [
    { path: 's.client', alias: 'c' },
    { path: 's.baseSubscription', alias: 'bs' },
    { path: 's.subscriptionsHasBuildings', alias: 'shb' },
    { path: 'bs.service', alias: 'svc' },
    { path: 'bs.partner', alias: 'p' },
    { path: 's.billingFrequency', alias: 'fq' },
    { path: 's.salesRep', alias: 'sr' },
    { path: 'sr.user', alias: 'u' },
  ],
};

type TSubscriptionEditorLinkData = Record<number, { pathSuffix?: string; linkText?: string; }>;

interface ISubscriptionEditorLinkProps {
  subscription: Subscriptions;
}

const SubscriptionEditorLink: React.FC<ISubscriptionEditorLinkProps> = ({ subscription }) => {
  const serviceBillingTypeToRowDataMap: TSubscriptionEditorLinkData = {
    [BILLING_TYPE_TIERED_UNIT_COUNT]: {
      pathSuffix: '/tiered-unit-count',
    },
  };

  const { pathSuffix, linkText } = (
    serviceBillingTypeToRowDataMap[subscription.baseSubscription.service.billingTypeId] ?? {}
  );

  return (
    <Link
      to={`/subscriptions${pathSuffix ?? ''}/${subscription.id}`}
      target="_blank"
    >
      {linkText ?? 'Try New Editor'}
    </Link>
  );
};

export const SubscriptionListRow = ({ subscription }: { subscription: Subscriptions; }) => {
  const baseSubscription = subscription.baseSubscription;
  if (!baseSubscription) {
    console.warn('Base SUBSCRIPTION', { subscription });
  }
  const service = subscription.baseSubscription.service ?? { invoiceItem: null };
  if (!service) {
    console.warn({ service });
  }

  return (
    <StyledTableRow
      customSx={{ userSelect: 'none' }}
      dark={true}
      hover={true}
    >
      <StyledTableCell
        dark={true}
        className="invoices-checkbox-column"
        style={{ textAlign: 'center' }}
      >
        <input type='checkbox' />
      </StyledTableCell>
      <StyledTableCell dark={true} style={{ textAlign: 'center' }}>
        {subscription.client.name}
      </StyledTableCell>
      <StyledTableCell dark={true} style={{ textAlign: 'left' }}>
        {service.invoiceItem ?? subscription.baseSubscription.partner.name}
      </StyledTableCell>
      <StyledTableCell dark={true} style={{ textAlign: 'left' }}>
        {subscription.invoiceDescription}
      </StyledTableCell>
      <StyledTableCell dark={true} style={{ textAlign: 'center' }}>
        {subscription.billingFrequency.name}
      </StyledTableCell>
      <StyledTableCell dark={true} style={{ textAlign: 'center' }}>
        {subscription.basePrice}
      </StyledTableCell>
      <StyledTableCell dark={true} style={{ textAlign: 'center' }}>
        {`${floatVal(getDiscountRateFromSubscription(subscription)) * 100}%`}
      </StyledTableCell>
      <StyledTableCell dark={true} style={{ textAlign: 'center' }}>
        {subscription.startDate}
      </StyledTableCell>
      <StyledTableCell dark={true} style={{ textAlign: 'center' }}>
        {subscription.expiryDate ?? '------'}
      </StyledTableCell>
      <StyledTableCell dark={true} style={{ textAlign: 'center' }}>
        {(
          subscription.rateExpiryDate === null
          || subscription.rateExpiryDate.toString() === '0000-00-00'
          || subscription.rateExpiryDate.toString() === '1899-11-30'
        ) ? '------' : subscription.rateExpiryDate.toString()
        }
      </StyledTableCell>
      <StyledTableCell dark={true} style={{ textAlign: 'center' }}>
        {subscription.salesRep?.user?.firstName} {subscription.salesRep?.user?.lastName}
      </StyledTableCell>
      <StyledTableCell dark={true} style={{ textAlign: 'center' }}>
        {/* <button>This will do stuff</button> */}
        <a
          href={`https://superlift.theliftsystem.com/subscriptions/view/${subscription.id}`}
          target="_blank"
          rel="noreferrer"
        >
          View
        </a><br />
        <SubscriptionEditorLink subscription={subscription} />
      </StyledTableCell>
    </StyledTableRow>
  );
};

export const SubscriptionList = () => {

  const loadedState = useAppSelector((state) => state.dynamic.data.subscriptions?.loadedState);
  const subscriptions = useAppSelector((state) => state.dynamic.data.subscriptions?.list);
  const subscriptionMetadata = useAppSelector((state) => state.dynamic.metaData);

  const [searchClient, setSearchClient] = useState<Nullable<IClientDropdownItem>>(null);
  const [searchField, setSearchField] = useState<Nullable<string>>(null);
  const [showSearch] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(20);

  useEffect(() => { getMany('subscriptions', relationsQueryCommand); }, []);

  const searchAndFilters: IQueryCommandOptions = useMemo(() => {
    return {
      ...relationsQueryCommand,
      where: [
        ...(searchClient ? [Equal('clientId', searchClient.id)] : []),
        ...(searchField ? [Like('invoiceDescription', searchField)] : []),
      ],
      page,
      limit,
    };
  }, [searchClient, searchField, page, limit]);

  useEffect(() => { getMany('subscriptions', searchAndFilters); }, [searchAndFilters]);

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setLimit(intVal(event.target.value));
    setPage(0);
  };

  return (
    <>
      <header className="Section-header">Subscription List</header>
      <Grid container spacing={2} mt={2} mb={2} justifyContent="center" alignItems="center">
        <Grid item xs={10}>
          {showSearch && <Paper variant='outlined' sx={{ padding: '1rem', backgroundColor: '#e0e0e0' }}>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={2}>Client:</Grid>
              <Grid item xs={10}>
                <ClientDropdown
                  clientId={!(searchClient?.id) ? null : intVal(searchClient.id)}
                  onClientChanged={(client) => { setSearchClient(client); }}
                  onClientFreeForm={() => { }}
                />
              </Grid>
              <Grid item xs={2}>Search:</Grid>
              <Grid item xs={10}>
                <TextField
                  placeholder="Search for description"
                  type="text"
                  sx={{ background: "white" }}
                  size="small"
                  fullWidth={true}
                  value={searchField ?? ''}
                  onChange={(e) => setSearchField(e.target.value || null)}
                />
              </Grid>
            </Grid>
          </Paper>}
        </Grid>
      </Grid>

      {loadedState === 'loading' && <Spinner state={loadedState} />}
      {loadedState === 'loaded' && <TableContainer component={Paper} elevation={12} sx={{ mb: 1 }}>

        <TablePagination
          component="div"
          sx={{ '& p': { marginTop: '1rem' } }}
          count={subscriptionMetadata?.totalRecords ?? 0}
          page={page}
          rowsPerPage={limit}
          showFirstButton
          showLastButton
          rowsPerPageOptions={rowsPerPageList}
          onPageChange={(e, newPage) => { setPage(newPage); }}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
        <MuiTable style={{ borderCollapse: 'collapse' }}>
          <TableHead>
            <StyledTableRow dark={true} style={{ cursor: 'pointer' }}>
              <StyledTableCell
                dark={true}
                style={{ textAlign: 'center' }}
                onClick={() => { }}
              >
                {/* <FormCheck
                  checked={allOnPageChecked && (invoices ?? []).length > 0}
                  onChange={() => toggleAllInvoiceCheckboxesForCurrentPage()}
                /> */}
              </StyledTableCell>

              <StyledTableCell dark={true}>Client</StyledTableCell>
              <StyledTableCell dark={true}>Item</StyledTableCell>
              <StyledTableCell dark={true}>Description</StyledTableCell>
              <StyledTableCell dark={true}>Freq.</StyledTableCell>
              <StyledTableCell dark={true}>Base Price</StyledTableCell>
              <StyledTableCell dark={true}>Discount Rate</StyledTableCell>
              {/* <StyledTableCell dark={true}>Notes</StyledTableCell> */}
              <StyledTableCell dark={true}>Start</StyledTableCell>
              <StyledTableCell dark={true}>Expiry</StyledTableCell>
              <StyledTableCell dark={true}>Rate Expiry</StyledTableCell>
              <StyledTableCell dark={true}>Sales Rep</StyledTableCell>
              <StyledTableCell dark={true}>&nbsp;</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {(subscriptions ?? []).map(
              (s) => <SubscriptionListRow subscription={s as unknown as Subscriptions} key={s.id} />,
            )}
          </TableBody>
        </MuiTable>
        <TablePagination
          component="div"
          sx={{ '& p': { marginTop: '1rem' } }}
          count={subscriptionMetadata?.totalRecords ?? 0}
          page={page}
          rowsPerPage={limit}
          showFirstButton
          showLastButton
          rowsPerPageOptions={rowsPerPageList}
          onPageChange={(e, newPage) => { setPage(newPage); }}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </TableContainer>}
    </>
  );
};
