import { Nullable } from "@jamesgmarks/utilities";
import {
  Accordion, AccordionDetails, AccordionSummary, Alert, Button, Grid,
} from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import React, { useState } from "react";
import { BuildingDropdown } from "src/components/parts/BuildingDropdown";

interface IBuildingsEditorProps {
  buildingsData: { id: number; name: string; }[];
  clientAccountId: Nullable<number>;
  onBuildingChanged: (building: { buildingName: string, id: number; }) => void;
  onBuildingRemoved: (id: number) => void;
}

export const BuildingsEditor: React.FC<IBuildingsEditorProps> = React.memo(({
  buildingsData,
  clientAccountId,
  onBuildingChanged,
  onBuildingRemoved,
}) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Accordion
      disabled={!clientAccountId}
      expanded={expanded && clientAccountId !== null}
      onChange={() => setExpanded((old) => !old)}
    >
      <AccordionSummary
        expandIcon={<GridExpandMoreIcon sx={{ fontSize: '2rem' }} />}
      >
        Manage buildings...
      </AccordionSummary>

      <AccordionDetails>
        <BuildingDropdown
          accountId={clientAccountId ?? -1}
          onBuildingChanged={onBuildingChanged}
          hideOptionLabelOnSelect={true}
        />

        <Grid container mt={3}>
          {
            (buildingsData.length > 0)
              ? (
                <Grid item xs={12} mb={2}>
                  <small><u><i>Buildings to save...</i></u></small>
                </Grid>
              )
              : null
          }

          <Grid item container xs={12}>
            {
              buildingsData.map(({ id, name }) => (
                <Grid
                  key={id}
                  item
                  container
                  xs={6}
                  mb={2}
                  alignItems='center'
                  spacing={2}
                >
                  <Grid item xs={9}>
                    <Alert severity='info' icon={false}>
                      <b>{name}</b> {`(${id})`}
                    </Alert>
                  </Grid>

                  <Grid item xs={3}>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => onBuildingRemoved(id)}
                    >
                      X
                    </Button>
                  </Grid>
                </Grid>
              ))
            }
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
});
