import { Nullable } from "@jamesgmarks/utilities";
import { TJsonNote } from "@llws/lift-entity-interfaces";
import { Button, Grid, Paper } from "@mui/material";

interface INoteProps {
  note: TJsonNote;
  onDelete: Nullable<() => void>;
}

export const Note: React.FC<INoteProps> = ({
  note: {
    note,
    timestamp,
    created_user_id,
    created_user_name,
  },
  onDelete,
}) => {
  return (
    <Paper variant='elevation' sx={{ background: '#f0f0f0', fontSize: '0.8rem', my: 1 }}>
      <Grid container alignItems='center' p={2}>
        <Grid item xs={5}>{note}</Grid>
        <Grid item xs={3} pl={2}>{timestamp}</Grid>
        <Grid item xs={3} pl={2}>{`${created_user_name} (${created_user_id})`}</Grid>
        <Grid item xs={1}>
          {
            onDelete
              ? (
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => onDelete()}
                >
                  X
                </Button>
              )
              : null
          }
        </Grid>
      </Grid>
    </Paper>
  );
};
