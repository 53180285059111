import { first, intVal, Nullable } from "@jamesgmarks/utilities";
import { Equal } from "@llws/dynamic-router-utils";
import {
  ICommissionDefaultScheme,
  ICommissionScheme,
  ICommissionSchemes,
  ISalesReps,
} from "@llws/lift-entity-interfaces";
import {
  AccordionDetails,
  AccordionSummary,
  Alert,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { StyledAccordion } from "../../tiered-unit-count/styledComponents";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { getMany } from "src/redux/features/dynamic/actions";
import { useAppSelector } from "src/redux/hooks";
import { SchemeEditor } from "./SchemeEditor";
import { JsonRenderer } from "../JsonRenderer";
import { makeYmd } from "src/app-utils";

export interface ICommissionSchemeData {
  id: Nullable<number>;
  subscriptionId: Nullable<number>;
  salesRepId: Nullable<number>;
  scheme: Nullable<ICommissionScheme>;
  label: string;
}

interface ICommissionEditorProps {
  isEditingSubscriptionCommission: boolean;
  setIsEditingSubscriptionCommission: React.Dispatch<React.SetStateAction<boolean>>;
  baseSubscriptionId: Nullable<number>;
  subscriptionId: Nullable<number>;
  salesRepId: Nullable<number>;
  onUpdateScheme: (updatedCommissionSchemeData: ICommissionSchemeData) => void;
  disabled?: boolean;
  hasErrors?: boolean;
  fetchCommissionSchemes: (subscriptionId: number) => Promise<void>;
}

export const CommissionEditor: React.FC<ICommissionEditorProps> = React.memo((
  {
    baseSubscriptionId,
    subscriptionId,
    salesRepId,
    onUpdateScheme,
    isEditingSubscriptionCommission,
    setIsEditingSubscriptionCommission,
    disabled = false,
    hasErrors = false,
    fetchCommissionSchemes,
  },
) => {
  const today = useMemo(() => new Date(), []);

  const [expanded, setExpanded] = useState(false);

  const [draftCommissionSchemeData, setDraftCommissionSchemeData] = (
    useState<ICommissionSchemeData>({
      id: null,
      subscriptionId,
      salesRepId,
      scheme: null,
      label: '',
    })
  );

  useEffect(() => {
    setDraftCommissionSchemeData((old) => ({
      ...old,
      salesRepId,
    }));
  }, [salesRepId]);

  const fetchSalesReps = (
    async () => {
      getMany('sales_reps',
        {
          alias: 'sr',
          relations: [{ path: 'sr.user', alias: 'u' }],
          where: [Equal('status', 'active')],
        });
    }
  );

  const fetchDefaultCommissionSchemes = useCallback(
    async (baseSubscriptionId: number) => {
      getMany(
        'default_commission_schemes',
        { where: [Equal('baseSubscriptionId', baseSubscriptionId)] },
      );
    }, [],
  );

  const storeDefaultCommissionSchemes = useAppSelector((store) => (
    store.dynamic.data.default_commission_schemes?.list ?? []));

  const storeSalesReps = useAppSelector((store) => (
    store.dynamic.data.sales_reps?.list ?? []));

  const salesRepsWithDefaultCommissionScheme = useMemo(() => (
    storeSalesReps
      .filter((salesRep) => (
        storeDefaultCommissionSchemes.find(({ salesRepId }) => salesRepId === salesRep.id)
      ))
  ), [storeDefaultCommissionSchemes, storeSalesReps]);

  const storeCommissionSchemes = useAppSelector((store) => (
    store.dynamic.data.commission_schemes?.list ?? []));

  useEffect(() => {
    if (storeSalesReps.length === 0) {
      fetchSalesReps();
    }
  }, [storeSalesReps.length]);

  useEffect(() => {
    if (baseSubscriptionId) {
      fetchDefaultCommissionSchemes(baseSubscriptionId);
    }
  }, [baseSubscriptionId, fetchDefaultCommissionSchemes]);

  useEffect(() => {
    if (draftCommissionSchemeData.subscriptionId) {
      fetchCommissionSchemes(draftCommissionSchemeData.subscriptionId);
    }
  }, [draftCommissionSchemeData.subscriptionId, fetchCommissionSchemes]);

  const salesRep = (
    useMemo<Nullable<ISalesReps>>(
      () => {
        if (salesRepsWithDefaultCommissionScheme.length === 0) {
          return null;
        }

        if (!draftCommissionSchemeData.salesRepId) {
          return first(salesRepsWithDefaultCommissionScheme)!;
        }

        return (
          salesRepsWithDefaultCommissionScheme
            .find(({ id }) => id === draftCommissionSchemeData.salesRepId)
          ?? first(salesRepsWithDefaultCommissionScheme)!
        );
      },
      [draftCommissionSchemeData.salesRepId, salesRepsWithDefaultCommissionScheme],
    )
  );

  const defaultSchemeForSalesRepAndBaseSubscription = (
    useMemo<Nullable<ICommissionDefaultScheme>>(() => {
      const defaultScheme = (
        salesRep
          ? (
            storeDefaultCommissionSchemes.find(
              ({ salesRepId }) => salesRepId === salesRep.id,
            )?.defaultScheme ?? null
          )
          : null
      );

      return (
        defaultScheme
          ? {
            ...defaultScheme,
            rates: (
              defaultScheme.rates
                .slice()
                .sort((a, b) => {
                  if (a.month === null) return -1;
                  if (b.month === null) return 1;
                  return a.month - b.month;
                })
            ),
          }
          : null
      );
    }, [salesRep, storeDefaultCommissionSchemes])
  );

  const concreteDefaultScheme = (
    useMemo<Nullable<ICommissionScheme>>(() => {
      if (!defaultSchemeForSalesRepAndBaseSubscription) {
        return null;
      }

      return {
        ...defaultSchemeForSalesRepAndBaseSubscription,
        version: '1',
        rates: (
          defaultSchemeForSalesRepAndBaseSubscription.rates
            .map(({ rate, month: startMonth }) => ({
              rate,
              startDate: (
                makeYmd(
                  new Date(
                    (new Date(today))
                      .setMonth(today.getMonth() + (startMonth ?? 0)),
                  ),
                  true,
                )
              ),
            }))
        ),
      };
    }, [defaultSchemeForSalesRepAndBaseSubscription, today])
  );

  const commissionSchemeForSalesRepAndSubscription = (
    useMemo<Nullable<ICommissionSchemes>>(() => (
      salesRep
        ? (
          storeCommissionSchemes.find(
            ({ salesRepId }) => salesRepId === salesRep.id,
          ) ?? null
        )
        : null
    ), [salesRep, storeCommissionSchemes])
  );

  const schemeForSalesRepAndSubscription = (
    useMemo<Nullable<ICommissionScheme>>(() => (
      commissionSchemeForSalesRepAndSubscription?.scheme ?? null
    ), [commissionSchemeForSalesRepAndSubscription])
  );

  useEffect(() => {
    setDraftCommissionSchemeData((old) => ({
      ...old,
      id: commissionSchemeForSalesRepAndSubscription?.id ?? null,
      subscriptionId: commissionSchemeForSalesRepAndSubscription?.subscriptionId ?? null,
      salesRepId: salesRep?.id ?? null,
      scheme: schemeForSalesRepAndSubscription ?? concreteDefaultScheme,
      label: commissionSchemeForSalesRepAndSubscription?.label ?? 'primary',
    }));
  }, [
    commissionSchemeForSalesRepAndSubscription?.id,
    commissionSchemeForSalesRepAndSubscription?.label,
    commissionSchemeForSalesRepAndSubscription?.subscriptionId,
    concreteDefaultScheme,
    salesRep?.id,
    schemeForSalesRepAndSubscription,
  ]);

  useEffect(() => {
    onUpdateScheme(draftCommissionSchemeData);
  }, [draftCommissionSchemeData, onUpdateScheme]);

  return (
    <StyledAccordion
      hasErrors={hasErrors}
      disabled={disabled}
      expanded={expanded && !disabled}
      onChange={() => setExpanded((old) => !old)}
    >
      <AccordionSummary expandIcon={<GridExpandMoreIcon sx={{ fontSize: '2rem' }} />}>
        Manage commissions...
      </AccordionSummary>

      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            {
              (salesRepsWithDefaultCommissionScheme.length > 0)
                ? (
                  <FormControl fullWidth>
                    <InputLabel>Sales rep</InputLabel>
                    <Select
                      label="Sales rep"
                      value={salesRep?.id}
                      size="small"
                      sx={{ background: "white" }}
                      onChange={(e) => {
                        setDraftCommissionSchemeData((old) => ({
                          ...old,
                          salesRepId: intVal(e.target.value) ?? null,
                        }));
                      }}
                    >
                      {
                        salesRepsWithDefaultCommissionScheme.map(({ id, user }, i) => (
                          <MenuItem key={id ?? i} value={id ?? -1}>
                            {user ? `${user.firstName} ${user.lastName} (${id})` : null}
                          </MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                )
                : null
            }
          </Grid>

          <Grid item xs={3}>
            {
              salesRep
                ? (
                  <TextField
                    label="Label"
                    type="text"
                    size="small"
                    fullWidth
                    sx={{ background: "white" }}
                    value={draftCommissionSchemeData.label}
                    onChange={(e) => {
                      setDraftCommissionSchemeData(
                        (old) => ({ ...old, label: e.target.value }),
                      );
                    }}
                  />
                )
                : null
            }
          </Grid>

          <Grid item container textAlign='center' xs={12} spacing={2}>
            {
              (
                salesRep?.user?.firstName
                && defaultSchemeForSalesRepAndBaseSubscription
                && concreteDefaultScheme
              )
                ? (
                  <>
                    <Grid item xs={6}>
                      <Typography variant='body1' mb={2}>
                        {`Template scheme for ${salesRep.user.firstName}`}
                      </Typography>

                      <JsonRenderer obj={defaultSchemeForSalesRepAndBaseSubscription} />
                    </Grid>

                    <Grid item container xs={6} justifyContent='center'>
                      <Typography variant='body1' mb={2}><b>Scheme to save...</b></Typography>

                      {
                        isEditingSubscriptionCommission
                          ? (
                            <SchemeEditor
                              commissionScheme={
                                draftCommissionSchemeData.scheme ?? concreteDefaultScheme
                              }
                              onCancel={() => { setIsEditingSubscriptionCommission(false); }}
                              onSave={(scheme) => {
                                setDraftCommissionSchemeData((old) => ({ ...old, scheme }));
                                setIsEditingSubscriptionCommission(false);
                              }}
                            />
                          )
                          : (
                            <>
                              <Grid item xs={12}>
                                <JsonRenderer
                                  backgroundColor="#d3f3ff"
                                  obj={
                                    draftCommissionSchemeData.scheme
                                      ? draftCommissionSchemeData.scheme
                                      : concreteDefaultScheme
                                  } />
                              </Grid>

                              <Grid item xs={12} mt={2}>
                                <Button
                                  variant="contained"
                                  color="warning"
                                  fullWidth
                                  onClick={() => { setIsEditingSubscriptionCommission(true); }}
                                >
                                  Edit
                                </Button>
                              </Grid>

                              {
                                schemeForSalesRepAndSubscription
                                  ? (
                                    <Grid item xs={12} mt={2}>
                                      <Button
                                        variant="contained"
                                        fullWidth
                                        onClick={() => {
                                          setDraftCommissionSchemeData((old) => ({
                                            ...old,
                                            scheme: schemeForSalesRepAndSubscription,
                                          }));
                                        }}
                                      >
                                        Use last-saved scheme for sales rep
                                      </Button>
                                    </Grid>
                                  )
                                  : null
                              }

                              {
                                concreteDefaultScheme
                                  ? (
                                    <Grid item xs={12} my={2}>
                                      <Button
                                        variant="contained"
                                        fullWidth
                                        onClick={() => {
                                          setDraftCommissionSchemeData((old) => ({
                                            ...old,
                                            scheme: {
                                              ...concreteDefaultScheme,
                                              version: old.scheme?.version ?? concreteDefaultScheme.version,
                                            },
                                          }));
                                        }}
                                      >
                                        Copy default scheme for sales rep
                                      </Button>
                                    </Grid>
                                  )
                                  : null
                              }
                            </>
                          )
                      }
                    </Grid>
                  </>
                )
                : (
                  <Grid item container xs={12} justifyContent='center' alignItems='center'>
                    {
                      (salesRepsWithDefaultCommissionScheme.length === 0)
                        ? (
                          <Grid item xs={12}>
                            <Alert icon={false} color="warning">
                              <i>
                                No sales reps found with a default scheme for this base subscription.
                              </i>
                            </Alert>
                          </Grid>
                        )
                        : null
                    }
                  </Grid>
                )
            }
          </Grid>
        </Grid>
      </AccordionDetails>
    </StyledAccordion>
  );
});
