import { floatVal } from "@jamesgmarks/utilities";
import { Checkbox, FormControlLabel, Grid, InputAdornment, TextField } from "@mui/material";
import { useState } from "react";

interface ISingleFamilyTierProps {
  pricePerUnit: number;
  chargeForFullMonth: boolean;
  onChange: (
    pricePerUnit: number,
    chargeForFullMonth: boolean
  ) => void;
}

interface ISingleFamilyTierFormData {
  pricePerUnit: string;
}

export const SingleFamilyTier: React.FC<ISingleFamilyTierProps> = ({
  pricePerUnit,
  chargeForFullMonth,
  onChange,
}) => {
  const [formData, setFormData] = useState<ISingleFamilyTierFormData>({
    pricePerUnit: `${pricePerUnit}`,
  });

  return (
    <>
      <Grid item xs={3}>
        <TextField
          label="Price per unit"
          type="text"
          size="small"
          sx={{ background: "white" }}
          value={formData.pricePerUnit}
          onChange={(e) => {
            if (/^(0|[1-9]\d*)?(\.\d{0,2})?$/.test(e.target.value)) {
              setFormData((old) => ({ ...old, pricePerUnit: e.target.value }));
            }
          }}
          onBlur={() => {
            if (['', '.'].includes(formData.pricePerUnit)) {
              setFormData((old) => ({ ...old, pricePerUnit: '0' }));
              onChange(0, chargeForFullMonth);
              return;
            }

            onChange(floatVal(formData.pricePerUnit), chargeForFullMonth);
          }}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </Grid>

      <Grid item container xs={12} mt={1} justifyContent='center'>
        <FormControlLabel
          control={
            <Checkbox
              checked={chargeForFullMonth}
              onChange={() => { onChange(pricePerUnit, !chargeForFullMonth); }}
              sx={{ '& .MuiSvgIcon-root': { fontSize: 30 } }}
            />}
          sx={{ userSelect: 'none' }}
          label="Charge SF buildings for full month"
        />
      </Grid>
    </>
  );
};
