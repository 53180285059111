import React, { useState } from "react";
import { TJsonNote } from "@llws/lift-entity-interfaces";
import {
  AccordionDetails, AccordionSummary, Button, Grid, TextField,
} from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { Note } from "./Note";
import { useAppSelector } from "src/redux/hooks";
import { StyledAccordion } from "../../tiered-unit-count/styledComponents";

interface INotesEditorProps {
  notes: TJsonNote[];
  onDeleteNote: (id: string) => void;
  onAddNote: (newNote: TJsonNote) => void;
  subscriptionLastSaved: Date;
  hasErrors?: boolean;
}

export const NotesEditor: React.FC<INotesEditorProps> = React.memo(({
  notes,
  onDeleteNote,
  onAddNote,
  subscriptionLastSaved,
  hasErrors = false,
}) => {
  const decodedToken = useAppSelector(state => state.auth?.decodedToken);

  const [expanded, setExpanded] = useState(false);
  const [newNoteMessage, setNewNoteMessage] = useState('');

  return (
    <StyledAccordion
      hasErrors={hasErrors}
      expanded={expanded}
      onChange={() => setExpanded((old) => !old)}
    >
      <AccordionSummary
        expandIcon={<GridExpandMoreIcon sx={{ fontSize: '2rem' }} />}
      >
        Manage notes...
      </AccordionSummary>

      <AccordionDetails>
        <Grid container>
          <Grid item xs={12}>
            {
              notes
                .slice()
                .sort((a, b) => (new Date(b.timestamp)).getTime() - (new Date(a.timestamp)).getTime())
                .map((note) => (
                  <Note
                    key={`${note.note}${note.timestamp}`}
                    note={note}
                    onDelete={
                      (subscriptionLastSaved < new Date(note.timestamp))
                        ? () => onDeleteNote(`${note.note}${note.timestamp}`)
                        : null
                    }
                  />
                ))
            }
          </Grid>

          <Grid item xs={12} container mt={3} mb={1} justifyContent='center'>
            <TextField
              label='New note'
              size='small'
              fullWidth
              multiline={true}
              sx={{ background: 'white' }}
              value={newNoteMessage}
              onChange={(e) => { setNewNoteMessage(e.target.value); }}
            />

            <Button
              disabled={newNoteMessage.trim().length === 0}
              variant='contained'
              sx={{ textAlign: 'center', mt: 2 }}
              onClick={
                () => {
                  const { userId, name, email } = (decodedToken ?? {});

                  const now = new Date();

                  const timestamp = (
                    // Match format of existing notes
                    new Intl.DateTimeFormat(
                      'en-US',             // Match AM/PM format, vs. a.m./p.m. of 'en-CA'
                      {
                        month: 'short',    // Abbreviated month name (e.g., "Nov")
                        day: 'numeric',    // Day of the month
                        year: 'numeric',   // Full year
                        hour: 'numeric',   // Hour
                        minute: '2-digit', // Minute with leading zero
                        second: '2-digit', // Second with leading zero
                        hour12: true,      // Use 12-hour format
                      },
                    ).format(now)
                  );

                  onAddNote({
                    note: newNoteMessage.trim(),
                    timestamp,
                    created_user_id: userId,
                    created_user_name: name ?? email,
                  });

                  setNewNoteMessage('');
                }}
            >
              Add new note
            </Button>

            <Grid item xs={12} textAlign='center' pt={1}>
              <small><i>Save subscription to persist...</i></small>
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    </StyledAccordion >
  );
});
