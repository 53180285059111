import { Nullable } from "@jamesgmarks/utilities";
import { ICommissionScheme } from "@llws/lift-entity-interfaces";
import { Alert, Button, Grid } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { SchemeRateEditor } from "./SchemeRateEditor";
import { makeYmd } from "src/app-utils";

interface ISchemeEditorProps {
  commissionScheme: ICommissionScheme;
  onCancel: () => void;
  onSave: (updatedSchemeData: ICommissionScheme) => void;
}

export const SchemeEditor: React.FC<ISchemeEditorProps> = ({
  commissionScheme, onCancel, onSave,
}) => {
  const augmentedSchemeRates = useMemo(
    () => commissionScheme.rates.map((rate, i) => ({
      id: i,
      ...rate,
      startDate: rate.startDate ?? makeYmd(new Date(), true),
    })),
    [commissionScheme],
  );

  const [nextId, setNextId] = useState(commissionScheme.rates.length);

  const [draftCommissionScheme, setDraftCommissionScheme] = (
    useState({ ...commissionScheme, rates: augmentedSchemeRates })
  );

  useEffect(() => {
    setDraftCommissionScheme({ ...commissionScheme, rates: augmentedSchemeRates });
  }, [commissionScheme, augmentedSchemeRates]);

  const sortedSchemeRates = useMemo(
    () => (
      draftCommissionScheme.rates
        .slice()
        .sort(
          (a, b) => (
            `${a.startDate}${a.id}` > `${b.startDate}${b.id}` ? 1 : -1
          ),
        )),
    [draftCommissionScheme.rates],
  );

  const schemeErrors = useMemo<string[]>(() => [
    ...(
      (
        (new Set(draftCommissionScheme.rates.map(({ startDate }) => startDate))).size
        !== draftCommissionScheme.rates.map(({ startDate }) => startDate).length
      )
        ? ['All rates must have a distinct start date']
        : []
    ),
    ...(
      draftCommissionScheme.rates.length === 0
        ? ['Must specify at least one rate']
        : []
    ),
  ], [draftCommissionScheme]);

  return (
    <Grid container justifyContent='center' alignItems='center'>
      <Grid item container xs={12} justifyContent="center" alignItems='center'>
        {
          sortedSchemeRates.map(
            (rate) => (
              <SchemeRateEditor
                key={rate.id}
                id={rate.id}
                rate={rate.rate}
                startDate={rate.startDate}
                onDelete={(id: number) => {
                  setDraftCommissionScheme((old) => ({
                    ...old,
                    rates: old.rates.filter((rate) => rate.id !== id),
                  }));
                }}
                onChange={
                  (id: number, rate: string, startDate: Nullable<string>) => {
                    setDraftCommissionScheme(
                      (old) => ({
                        ...old,
                        rates: [
                          ...old.rates.filter((rate) => rate.id !== id),
                          {
                            id,
                            rate,
                            startDate: startDate ?? makeYmd(new Date(), true),
                          },
                        ],
                      }),
                    );
                  }}
              />
            ),
          ) ?? null
        }
      </Grid>

      <Grid item container xs={12} spacing={1} pt={schemeErrors.length > 0 ? 2 : 0}>
        {
          schemeErrors.map((errorMessage) => (
            <Grid key={errorMessage} item xs={12}>
              <Alert icon={false} color="warning">{errorMessage}</Alert>
            </Grid>
          ))
        }
      </Grid>

      <Grid item container xs={12} justifyContent='center' pt={schemeErrors.length > 0 ? 2 : 1}>
        <Button
          variant="contained"
          disabled={
            draftCommissionScheme.rates
              .filter((r) => r.startDate === null)
              .length > 0
          }
          onClick={() => {
            setDraftCommissionScheme((old) => ({
              ...old,
              rates: [
                ...old.rates,
                {
                  id: nextId,
                  startDate: makeYmd(new Date(), true),
                  rate: '0',
                },
              ],
            }));

            setNextId((old) => old + 1);
          }}
        >
          Add row
        </Button>
      </Grid>

      <Grid item container xs={12} mt={4} mb={2} justifyContent="center" spacing={2}>
        <Grid item>
          <Button
            variant="contained"
            color="error"
            onClick={onCancel}
          >
            Cancel
          </Button>
        </Grid>

        <Grid item>
          <Button
            disabled={schemeErrors.length > 0}
            variant="contained"
            color="success"
            onClick={() => {
              onSave({
                ...draftCommissionScheme,
                rates: (
                  sortedSchemeRates
                    .map(({ startDate, rate }) => ({ startDate, rate }))
                ),
              });
            }}
          >
            Update
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
