import { Link } from "react-router-dom";
import { OpenInNew } from "@mui/icons-material";

interface ICreateCreateTieredUnitCountSubscriptionLinkProps {
  textOverride?: string;
}

export const CreateTieredUnitCountSubscriptionLink: React.FC<ICreateCreateTieredUnitCountSubscriptionLinkProps
> = ({ textOverride }) => (
  <Link
    to={{ pathname: "/subscriptions/tiered-unit-count" }}
    target="_blank"
    rel="noopener noreferrer"
  >
    {textOverride || 'Create Unit-Count Based Subscription'}
    <OpenInNew sx={{ ml: 0.5, mb: 0.25 }} />
  </Link>
);
