import {
  AccordionDetails, AccordionSummary, Alert, Box, Button, Grid, Typography,
} from "@mui/material";
import { StyledAccordion } from "./styledComponents";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import React, { useState } from "react";
import { JsonRenderer } from "../EditorComponents/JsonRenderer";
import { TieredUnitCountConfigurationEditor } from "./TieredUnitCountConfigurationEditor";
import {
  isITieredUnitCountConfiguration,
  ITieredUnitCountConfiguration,
  sortMultiFamilyTiers,
} from "@llws/hydra-shared";
import { ITieredUnitCountSubscriptionData } from "./TieredUnitCountEditor";
import { ISubscriptions } from "@llws/lift-entity-interfaces";
import { Nullable } from "@jamesgmarks/utilities";

interface ITieredUnitCountConfigurationAccordionProps {
  isEditingSubscriptionConfiguration: boolean;
  setIsEditingSubscriptionConfiguration: React.Dispatch<React.SetStateAction<boolean>>;
  baseSubscriptionId: Nullable<number>;
  baseSubscriptionConfiguration: Nullable<ITieredUnitCountConfiguration>;
  subscriptionConfiguration: Nullable<ITieredUnitCountConfiguration>;
  setSubscriptionData: React.Dispatch<React.SetStateAction<ITieredUnitCountSubscriptionData>>;
  storeSubscription: Nullable<ISubscriptions>;
  hasErrors?: boolean;
}

export const TieredUnitCountConfigurationAccordion: React.FC<ITieredUnitCountConfigurationAccordionProps> = (
  React.memo(({
    isEditingSubscriptionConfiguration,
    setIsEditingSubscriptionConfiguration,
    baseSubscriptionId,
    baseSubscriptionConfiguration,
    subscriptionConfiguration,
    setSubscriptionData,
    storeSubscription,
    hasErrors = false,
  }) => {
    const [expanded, setExpanded] = useState(false);

    const onCopyBaseSubscriptionConfiguration = () => {
      setSubscriptionData((old) => ({
        ...old,
        subscriptionConfiguration: old.baseSubscriptionConfiguration,
      }));
    };

    const onCopyOriginalSubscriptionConfiguration = () => {
      const subscriptionConfiguration = (
        storeSubscription?.customConfiguration?.tieredUnitCountConfig ?? null);

      if (isITieredUnitCountConfiguration(subscriptionConfiguration)) {
        setSubscriptionData((old) => ({ ...old, subscriptionConfiguration }));
      }
    };

    const disabled = !baseSubscriptionConfiguration;

    return (
      <StyledAccordion
        hasErrors={hasErrors && !disabled}
        disabled={disabled}
        expanded={expanded && !disabled}
        onChange={() => setExpanded((old) => !old)}
      >
        <AccordionSummary
          expandIcon={<GridExpandMoreIcon sx={{ fontSize: '2rem' }} />}
        >
          Manage configuration...
        </AccordionSummary>

        <AccordionDetails>
          <Grid item container xs={12} spacing={1} textAlign='center'>
            <Grid item xs={isEditingSubscriptionConfiguration ? 5 : 6}>
              <Typography variant="h6" textAlign='center' marginBottom={2}>
                Base subscription configuration
              </Typography>

              {
                (baseSubscriptionId && baseSubscriptionConfiguration)
                  ? (
                    <JsonRenderer
                      obj={{
                        ...baseSubscriptionConfiguration,
                        MF: (
                          baseSubscriptionConfiguration.MF
                            .slice()
                            .sort(sortMultiFamilyTiers)
                        ),
                      }}
                      keyReplacements={{ SF: 'SF', MF: 'MF' }}
                      dollarValueKeys={['pricePerUnit', 'pricePerProperty']}
                      customStyles={{ SF: { marginTop: 20 } }}
                    />
                  )
                  : (
                    <Alert
                      icon={false}
                      color="warning"
                      sx={{ display: 'flex', justifyContent: 'center' }}
                    >
                      <i>Select a base subscription, above.</i>
                    </Alert>
                  )
              }
            </Grid>

            <Grid item xs={isEditingSubscriptionConfiguration ? 7 : 6}>
              <Typography variant="h6" textAlign='center' marginBottom={2}>
                <b>Subscription configuration to save...</b>
              </Typography>

              {
                (isEditingSubscriptionConfiguration && subscriptionConfiguration)
                  ? (
                    <TieredUnitCountConfigurationEditor
                      config={subscriptionConfiguration}
                      onSave={(updatedConfiguration) => {
                        setSubscriptionData((old) => ({
                          ...old,
                          subscriptionConfiguration: updatedConfiguration,
                        }));

                        setIsEditingSubscriptionConfiguration(false);
                      }}
                      onCancel={() => { setIsEditingSubscriptionConfiguration(false); }}
                      onInvalidConfiguration={() => { setIsEditingSubscriptionConfiguration(false); }}
                    />
                  )
                  : (
                    <>
                      {
                        subscriptionConfiguration
                          ? (
                            <Box>
                              <JsonRenderer
                                obj={{
                                  ...subscriptionConfiguration,
                                  MF: (
                                    subscriptionConfiguration.MF
                                      .slice()
                                      .sort(sortMultiFamilyTiers)
                                  ),
                                }}
                                backgroundColor="#d3f3ff"
                                keyReplacements={{ SF: 'SF', MF: 'MF' }}
                                dollarValueKeys={['pricePerUnit', 'pricePerProperty']}
                                customStyles={{ SF: { marginTop: 20 } }}
                              />

                              <Grid item xs={12} mt={2}>
                                <Button
                                  variant="contained"
                                  fullWidth
                                  color="warning"
                                  onClick={() => { setIsEditingSubscriptionConfiguration(true); }}
                                >
                                  Edit
                                </Button>
                              </Grid>
                            </Box>
                          )
                          : (
                            (baseSubscriptionId && baseSubscriptionConfiguration)
                              ? (
                                <Alert
                                  icon={false}
                                  color="warning"
                                  sx={{ display: 'flex', justifyContent: 'center' }}
                                >
                                  <i>No configuration specified for subscription.</i>
                                </Alert>
                              )
                              : null
                          )
                      }

                      {
                        isITieredUnitCountConfiguration(
                          storeSubscription?.customConfiguration?.tieredUnitCountConfig,
                        )
                          ? (
                            <Grid item xs={12} mt={2}>
                              <Button
                                variant='contained'
                                fullWidth
                                onClick={onCopyOriginalSubscriptionConfiguration}
                              >
                                Use last-saved subscription configuration
                              </Button>
                            </Grid>
                          )
                          : null
                      }

                      {
                        (baseSubscriptionId && baseSubscriptionConfiguration)
                          ? (
                            <Grid item xs={12} my={2}>
                              <Button
                                variant='contained'
                                fullWidth
                                onClick={onCopyBaseSubscriptionConfiguration}
                              >
                                Copy base subscription configuration
                              </Button>
                            </Grid>
                          )
                          : null
                      }
                    </>
                  )
              }
            </Grid>
          </Grid>
        </AccordionDetails>
      </StyledAccordion>
    );
  })
);
