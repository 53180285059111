import { Alert, Button, Grid, Typography } from "@mui/material";
import {
  areAllMultiFamilyTierMaxUnitsUnique,
  doesExactlyOneMultiFamilyTierHaveNullMaxUnits,
  isITieredUnitCountConfiguration,
  ITieredUnitCountConfiguration,
  ITieredUnitCountMultiFamilyConfiguration,
} from "@llws/hydra-shared";
import { useEffect, useMemo, useState } from "react";
import { showMessage } from "src/redux/features/messaging/actions";
import { Nullable } from "@jamesgmarks/utilities";
import { SingleFamilyTier } from "./SingleFamilyTier";
import { MultiFamilyTier } from "./MultiFamilyTier";

interface IConfigurationEditorProps {
  config: ITieredUnitCountConfiguration;
  onCancel: () => void;
  onSave: (updatedConfiguration: ITieredUnitCountConfiguration) => void;
  onInvalidConfiguration?: () => void;
}

export const TieredUnitCountConfigurationEditor: React.FC<IConfigurationEditorProps> = (
  { config, onCancel, onSave, onInvalidConfiguration },
) => {
  const augmentedMultiFamilyTiers = useMemo(
    () => config.MF.map((tier, i) => ({
      id: i,
      ...tier,
    })),
    [config],
  );

  // This variable is used to assign unique IDs to multi-family tiers. In the UI
  // a user can add new/blank/default tiers to the editor. There is no way to
  // distinguish each tier. This indeterminism means that sometimes a user will
  // click a row, causing a re-render, and the order of the rows will change, and
  // the cursor will appear to jump around. To solve this, the initial tiers are
  // given a permanent ID based on their index, and added rows will get the next
  // ID. These IDs are NOT saved to the database.
  const [nextId, setNextId] = useState(augmentedMultiFamilyTiers.length);

  const [draftConfig, setDraftConfig] = useState({
    ...config,
    MF: augmentedMultiFamilyTiers,
  });

  const sortedMultiFamilyTiers = useMemo(() => (
    draftConfig.MF
      .slice()
      .sort((a, b) => {
        if (a.maxUnits === null && b.maxUnits === null) {
          return `${a.pricePerProperty}${a.id}` > `${b.pricePerProperty}${b.id}` ? -1 : 1;
        }

        if (a.maxUnits === null) { return 1; }
        if (b.maxUnits === null) { return -1; }

        return `${a.maxUnits}${a.pricePerProperty}${a.id}` > `${b.maxUnits}${b.pricePerProperty}${b.id}` ? 1 : -1;
      })
  ), [draftConfig]);

  useEffect(() => {
    if (!isITieredUnitCountConfiguration(config)) {
      showMessage({ message: "This subscription configuration is invalid." });
      onInvalidConfiguration?.();
      return;
    }

    setDraftConfig({
      ...config,
      MF: augmentedMultiFamilyTiers,
    });
  }, [config, augmentedMultiFamilyTiers, onInvalidConfiguration]);

  const configurationErrors = useMemo<string[]>(() => [
    ...(
      !doesExactlyOneMultiFamilyTierHaveNullMaxUnits(draftConfig.MF)
        ? ['Must have exactly 1 MF tier with an empty \'maximum units\' (catch-all)']
        : []
    ),
    ...(
      !areAllMultiFamilyTierMaxUnitsUnique(draftConfig.MF)
        ? ['\'Maximum units\' values must be unique across all MF tiers']
        : []
    ),
    ...(
      draftConfig.MF.some((tier) => tier.maxUnits === 0)
        ? ['\'Maximum units\' value cannot be 0 for MF tier']
        : []
    ),
  ], [draftConfig]);

  const multiFamilyTierKeys: (
    keyof ITieredUnitCountMultiFamilyConfiguration
  )[] = ['maxUnits', 'pricePerProperty', 'unitOfMeasure'];

  return (
    <Grid container justifyContent='center' alignItems='center' pl={1}>
      <Grid item container xs={12} justifyContent='center' alignItems='center' spacing={2}>
        <Grid item xs={12}>
          <Typography variant="body1" mt={3} mb={1}><u>Single-family</u></Typography>
        </Grid>

        <Grid item container xs={12} justifyContent='center'>
          <SingleFamilyTier
            pricePerUnit={draftConfig.SF.pricePerUnit}
            chargeForFullMonth={draftConfig.SF.chargeForFullMonth}
            onChange={(pricePerUnit: number, chargeForFullMonth: boolean) => {
              setDraftConfig((old) => ({
                ...old,
                SF: {
                  ...old.SF,
                  pricePerUnit,
                  chargeForFullMonth,
                },
              }));
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body1" mt={3} mb={1}><u>Multi-family</u></Typography>
        </Grid>
        {
          sortedMultiFamilyTiers.map(
            ({ id, pricePerProperty, maxUnits, unitOfMeasure }) => (
              <MultiFamilyTier
                key={id}
                id={id}
                pricePerProperty={pricePerProperty}
                maxUnits={maxUnits}
                unitOfMeasure={unitOfMeasure}
                onDelete={(id: number) => {
                  setDraftConfig((old) => ({
                    ...old,
                    MF: old.MF.filter((tier) => tier.id !== id),
                  }));
                }}
                onChange={(
                  id: number,
                  pricePerProperty: number,
                  maxUnits: Nullable<number>,
                  unitOfMeasure: string,
                ) => {
                  setDraftConfig((old) => ({
                    ...old,
                    MF: [
                      ...old.MF.filter((tier) => tier.id !== id),
                      {
                        id,
                        pricePerProperty,
                        maxUnits,
                        unitOfMeasure,
                      },
                    ],
                  }));
                }}
              />
            ),
          )
        }

        <Grid item container xs={12} spacing={1}>
          {
            configurationErrors.map((errorMessage) => (
              <Grid key={errorMessage} item xs={12}>
                <Alert icon={false} color="warning">{errorMessage}</Alert>
              </Grid>
            ))
          }
        </Grid>

        <Button
          variant="contained"
          sx={{ mt: configurationErrors.length ? 2 : 1 }}
          onClick={() => {
            setDraftConfig((old) => ({
              ...old,
              MF: [
                ...old.MF,
                {
                  id: nextId,
                  pricePerProperty: 0,
                  maxUnits: null,
                  unitOfMeasure: "multi-family",
                },
              ],
            }));

            setNextId((old) => old + 1);
          }}
        >
          Add tier
        </Button>
      </Grid>
      <Grid item xs={12}></Grid>
      <Grid item container xs={12} mt={4} justifyContent="center" spacing={2}>
        <Grid item>
          <Button
            variant="contained"
            color="error"
            onClick={onCancel}
          >
            Cancel
          </Button>
        </Grid>

        <Grid item>
          <Button
            disabled={
              configurationErrors.length > 0
              || !isITieredUnitCountConfiguration(draftConfig)
            }
            variant="contained"
            color="success"
            onClick={
              () => onSave({
                ...draftConfig,
                MF: (
                  sortedMultiFamilyTiers
                    .map((c) => (
                      multiFamilyTierKeys
                        .reduce((acc, key) => ({ ...acc, [key]: c[key] }), {})
                    ))
                ) as ITieredUnitCountMultiFamilyConfiguration[],
              })
            }
          >
            Update
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
