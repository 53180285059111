import { floatVal, Nullable } from "@jamesgmarks/utilities";
import { Button, Grid, TextField } from "@mui/material";
import { useState } from "react";
import { dateFromYmd, makeYmd } from "src/app-utils";
import { HHDatePicker } from "src/components/parts/HHDatePicker";

interface ISchemeRateEditorProps {
  id: number;
  rate: string;
  startDate: string;
  onChange: (id: number, rate: string, startDate: Nullable<string>) => void;
  onDelete: (id: number) => void;
}

export const SchemeRateEditor: React.FC<ISchemeRateEditorProps> = ({
  id, rate, startDate, onChange, onDelete,
}) => {
  const [formRate, setFormRate] = useState(rate);

  return (
    <Grid item container xs={12} justifyContent='center' spacing={1} alignItems='center'>
      <Grid item xs={4} sx={{ marginTop: '15px' }}>
        <TextField
          label="Rate"
          type="text"
          size="small"
          sx={{ background: "white" }}
          value={formRate}
          onChange={(e) => {
            if (/^0(\.\d*)?$/.test(e.target.value)) {
              setFormRate(e.target.value);
            }
          }}
          onBlur={() => { onChange(id, `${floatVal(formRate)}`, startDate); }}
        />
      </Grid>

      <Grid item xs={5} sx={{ marginTop: '15px' }}>
        <HHDatePicker
          label='Start date'
          value={dateFromYmd(startDate)}
          onChange={(date: Nullable<Date>) => {
            onChange(
              id,
              rate,
              (date === null || isNaN(date.getTime())) ? null : makeYmd(date, true),
            );
          }}
        />
      </Grid>

      <Grid item xs={1} sx={{ marginTop: '15px' }}>
        <Button
          variant="contained"
          color="error"
          onClick={() => onDelete(id)}
        >
          X
        </Button>
      </Grid>
      <Grid item xs={1}></Grid>
    </Grid>
  );
};
