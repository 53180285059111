import { useState } from "react";
import { floatVal, intVal, keys, Nullable } from "@jamesgmarks/utilities";
import {
  Button, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select, TextField,
} from "@mui/material";
import { EUnitOfMeasure, tieredUnitCountUnitOfMeasurePrefix } from "@llws/hydra-shared";

const validMultiFamilyUnitsOfMeasure = (
  keys(EUnitOfMeasure)
    .filter((key) => key.startsWith(tieredUnitCountUnitOfMeasurePrefix) && !key.endsWith('single-family'))
    .map((key) => key.replace(tieredUnitCountUnitOfMeasurePrefix, ''))
);

interface IMultiFamilyTierProps {
  id: number;
  pricePerProperty: number;
  maxUnits: Nullable<number>;
  unitOfMeasure: string;
  onChange: (
    id: number,
    pricePerProperty: number,
    maxUnits: Nullable<number>,
    unitOfMeasure: string
  ) => void;
  onDelete: (id: number) => void;
}

interface IMultiFamilyTierFormData {
  pricePerProperty: string;
  maxUnits: string;
}

export const MultiFamilyTier: React.FC<IMultiFamilyTierProps> = ({
  id,
  pricePerProperty,
  maxUnits,
  unitOfMeasure,
  onChange,
  onDelete,
}) => {
  const [formData, setFormData] = useState<IMultiFamilyTierFormData>({
    pricePerProperty: `${pricePerProperty}`,
    maxUnits: `${maxUnits ?? ''}`,
  });

  return (
    <>
      <Grid item xs={3} sx={{ padding: '0 !important', marginTop: '15px' }}>
        <TextField
          label="Price per property"
          type="text"
          size="small"
          sx={{ background: "white" }}
          value={formData.pricePerProperty}
          onChange={(e) => {
            if (/^(0|[1-9]\d*)?(\.\d{0,2})?$/.test(e.target.value)) {
              setFormData((old) => ({ ...old, pricePerProperty: e.target.value }));
            }
          }}
          onBlur={() => {
            if (['', '.'].includes(formData.pricePerProperty)) {
              setFormData((old) => ({ ...old, pricePerProperty: '0' }));
              onChange(id, 0, maxUnits, unitOfMeasure);
              return;
            }

            onChange(
              id,
              floatVal(formData.pricePerProperty),
              maxUnits,
              unitOfMeasure,
            );
          }}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </Grid>

      <Grid item xs={3} sx={{ padding: '0 !important', margin: '15px 0 0 6px' }}>
        <TextField
          label="Maximum units"
          type="text"
          size="small"
          sx={{ background: "white" }}
          value={formData.maxUnits}
          onChange={(e) => {
            if (/^([1-9]\d*)?$/.test(e.target.value)) {
              setFormData((old) => ({ ...old, maxUnits: e.target.value }));
            }
          }}
          onBlur={() => {
            onChange(
              id,
              pricePerProperty,
              formData.maxUnits === '' ? null : intVal(formData.maxUnits),
              unitOfMeasure,
            );
          }}
        />
      </Grid>

      <Grid item xs={4} sx={{ padding: '0 !important', margin: '15px 0 0 6px' }}>
        <FormControl fullWidth>
          <InputLabel>Tier name</InputLabel>
          <Select
            value={unitOfMeasure}
            label="Tier name"
            size="small"
            sx={{ background: "white" }}
            onChange={(e) => {
              onChange(
                id,
                pricePerProperty,
                maxUnits,
                e.target.value,
              );
            }}
          >
            {
              validMultiFamilyUnitsOfMeasure.map((unitOfMeasure) => (
                <MenuItem key={unitOfMeasure} value={unitOfMeasure}>{unitOfMeasure}</MenuItem>
              ))
            }
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={1} sx={{ padding: '0 !important', margin: '15px 0 0 6px' }}>
        <Button
          variant="contained"
          color="error"
          onClick={() => onDelete(id)}
        >
          X
        </Button>
      </Grid>
    </>
  );
};
